.superText {
  animation: superTextAni 2s;
  animation-timing-function: ease-in-out;
}


@keyframes superTextAni {
  0%, 40% {
    transform: scale(0.6);
    opacity: 0.0;
  }

  41% {
    transform: scale(0.8);
    opacity: 0.3;
  }

  75%, 100% {
    transform: scale(1);
    opacity: 1;
  }
}



.subText {
  animation: subTextAni 2s;
  animation-timing-function: ease-in-out;
}



@keyframes subTextAni {
  0%, 60% {
    bottom: -5%;
    opacity: 0;
  }

  100% {
    bottom: 3%;
    opacity: 1;
  }
}



.introText {
  animation: introTextAni 1s;
  animation-timing-function: ease-in-out;
  width: 50%;
  pointer-events: none  ;
}


@keyframes introTextAni {
  0%, 20% {
    transform: scale(0.75);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.disapear {
  opacity: 0 !important;
  transition: 0.5s ease-in !important;
}



.startBtn {
  animation: startBtn 1.2s infinite;

}

.startBtn:hover {
  /* animation: startBtn-hover 0.4s ease-in infinite; */
  cursor: pointer;
  /* transition:  0.2s ease-out; */
  /* transform:  */
}

@keyframes startBtn {
  0%, 60%, 100% {
    transform: scale(1.0);

  }

  30% {
    transform: scale(1.1);
  }
}

@keyframes startBtn-hover {
  0%, 100% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(1);
  }

}




* {
  user-select: none
}

.changeTran {
  width: 8%;
  height: 8%;
  position: fixed;
  right: -80%;
  top: 30%;
  animation: changeTranAni 10s;

}

@keyframes changeTranAni {
  0% {
    transform: rotateZ(120deg);
  }

  8% {
    transform: rotateZ(-65deg);
  }

  16%, 100% {
    transform: translateY(4000%) rotateZ(-65deg);
  }
}


.changeTran1 {
  width: 8%;
  height: 8%;
  background-color: black;
  position: fixed;
  right: -95%;
  top: 30%;
  animation: changeTranAni1 10s;

}

@keyframes changeTranAni1 {
  0% {
    transform: rotateZ(120deg);
  }

  8% {
    transform: rotateZ(-65deg);
  }

  16%, 100% {
    transform: translateY(4000%) rotateZ(-65deg);
  }
}

.mybg {
  height: 100%;
  text-align: center;
  margin: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: "fixed";
  top: 0px;

}



.hideObject{
  opacity: 0;
  pointer-events: none;
}

.showObject{
  opacity: 1;
}




.textAppear {
  animation: textAppearAni 1.6s;
  /* animation-delay: 0.6s; */
}

.buttonTransition {
  transition: 0.2s;
}

@keyframes textAppearAni {
  0%, 10% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.playBtn {
  transform: scale(1);
  transition: ease-in 0.2s;
  cursor: pointer;

}

.playBtn:hover {
  /* transform: scale(1.05); */
  /* transition: ease-in 0.2s; */
  cursor: pointer;
}

.successBtn {
  animation: fadein 0.8s;
}

.background-move {
  animation: fadein1 0.5s ease-out;
}

@keyframes fadein1 {
  from {
    /* transform: translateX(100%); */
    opacity: 0;
  }

  to {
    /* transform: translateX(0%); */
    opacity: 1;
  }
}

.sceneBase {
  width: 100%;
  height: 100%;
}


.aniObject {
  animation: fadein 1s;
  /* animation-delay: 0.6s; */
}

.introAni {
  animation: fadein 0.8s;
}


@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.starBtn1 {
  animation: fadein 0.5s;
}

.starBtn2 {
  animation: fadein 1s;
}

.starBtn3 {
  animation: fadein 1.5s;
}

.starBtn4 {
  animation: fadein 2s;
}

.starBtn5 {
  animation: fadein 2.5s;
}

.starBtn6 {
  animation: fadein 3s;

}

.expressBtn {
  width: 100%;
  height: 100%;
  margin-left: 0%;
  margin-top: 0%;
  cursor: pointer;
}


.expressBtn:hover {
  width: 106%;
  height: 106%;
  margin-left: -3%;
  margin-top: -3%;
}



/* -------------------------------- */
/* BaseProp */
.baseProp {
  position: fixed;
}

/* baseImage */
.baseImage {
  position: absolute;
  user-select: none;
  pointer-events: none;
}

.hide {
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}

.show {
  opacity: 1;
}

.movingTopDown {
  animation: movingTopDownAni 1.6s infinite;
  animation-timing-function: linear;
  cursor: pointer;
}

@keyframes movingTopDownAni {
  0%, 100% {
    transform: translateY(0%);
  }

  25% {
    transform: translateY(4%);
  }

  75% {
    transform: translateY(-4%);
  }
}

.movingDownTop {
  animation: movingDownTopAni 1.6s infinite;
  animation-timing-function: linear;
  cursor: pointer;
}

@keyframes movingDownTopAni {
  0%, 100% {
    transform: translateY(0%);
  }

  25% {
    transform: translateY(-4%);
  }

  75% {
    transform: translateY(4%);
  }
}

/* ---- */

.movingTopDown1 {
  animation: movingTopDownAni1 2s infinite;
  animation-timing-function: linear;
  cursor: pointer;
}

@keyframes movingTopDownAni1 {
  0%, 100% {
    transform: translateY(0%);
  }

  25% {
    transform: translateY(1%);
  }

  75% {
    transform: translateY(-1%);
  }
}

.movingDownTop1 {
  animation: movingDownTopAni1 2s infinite;
  animation-timing-function: linear;
  cursor: pointer;
}

@keyframes movingDownTopAni1 {
  0%, 100% {
    transform: translateY(0%);
  }

  25% {
    transform: translateY(-1%);
  }

  75% {
    transform: translateY(1%);
  }
}

.startBtn {
  animation: startBtn 1.2s infinite;

}

.startBtn:hover {
  /* animation: startBtn-hover 0.4s ease-in infinite; */
  cursor: pointer;
  /* transition:  0.2s ease-out; */
  /* transform:  */
}

@keyframes startBtn {
  0%, 60%, 100% {
    transform: scale(1.0);

  }

  30% {
    transform: scale(1.1);
  }
}

.sliderRightLeft {
  animation: sliderRightLeftAni 2s ease-in;
}

@keyframes sliderRightLeftAni {
  0% {
    right: -80%
  }

  20%, 100% {
    right: 0%
  }


}

img {
  user-select: none;
  pointer-events: none;
}

.introText {
  animation: introTextAni 1.4s;
  animation-timing-function: ease-in-out;
}


@keyframes introTextAni {
  0%, 50% {
    transform: scale(0.75);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.scaleIncrease {
  animation: introTextAni 1.6s;
  animation-timing-function: ease-in;
}

@keyframes scaleIncrease {
  0%, 30% {
    transform: scale(0.6);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.ellipse1 {
  shape-outside: ellipse(50% 15%);
  clip-path: ellipse(50% 15%);
  background: lightblue;
  animation: ellipseAni1 1.1s infinite;
  animation-timing-function: linear;
  animation-delay: 0.2s;
}

@keyframes ellipseAni1 {
  0%  , 100%{
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-3%);
  }

  75% {
    transform: translateX(3%);
  }
}

.ellipse2 {
  shape-outside: ellipse(50% 15%);
  clip-path: ellipse(50% 15%);
  background: lightblue;
  animation: ellipseAni2 1s infinite;
  animation-timing-function: linear;
  animation-delay: 0.4s;
}

@keyframes ellipseAni2 {
  0%  , 100%{
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-3%);
  }

  75% {
    transform: translateX(3%);
  }
}


.ellipse3 {
  shape-outside: ellipse(50% 20%);
  clip-path: ellipse(50% 20%);
  background: lightblue;
  animation: ellipseAni3 1s 3;
  animation-timing-function: linear;
  animation-delay: 2s;
}

@keyframes ellipseAni3 {
  0%  , 100%{
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-3%);
  }

  75% {
    transform: translateX(3%);
  }
}


.ellipse4 {
  shape-outside: ellipse(50% 20%);
  clip-path: ellipse(50% 20%);
}


/* .playBtn:active {
  background-color: rgba(251, 255, 0, 0.85);
  border-radius: 50%;
  transition: 0.01s;
} */



.commonButton:active {
  transition: 0.1s;
  transform: scale(0.95);
}

.commonButton {
  transform: scale(1);
  transition: ease-in 0.1s;
}

.excellentText {
  animation: excellentTextAni 1.4s;
  animation-timing-function: ease-in-out;
}


@keyframes excellentTextAni {
  0%, 50% {
    transform: scale(0.2);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.aniObjectDelay {
  animation: fadein3 1.5s;
  /* animation-delay: 0.6s; */
}

@keyframes fadein3 {
  0% , 40% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
canvas{
  width:100%;
  height : 100%;
}